import React, { useState, useEffect } from "react";
import "./App.css";
import MindARViewer from "./mindar-viewer";
import useRouterQuery from "./hooks/query";
import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import {
  HStack,
  Box,
  Image as ChakraImage,
  useToast,
  Text,
  VStack,
  Button,
  SimpleGrid,
} from "@chakra-ui/react";
import FullPageSpinner from "./Spinner";
import { FiChevronLeft } from "react-icons/fi"

function App() {
  const routerQuery = useRouterQuery();
  const [screenHeight, setScreenHeight] = useState(0);
  const [screenWidth, setScreenWidth] = useState(0);
  const getTourExperience = async (tourId) => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/wp-json/wp/v2/product/${tourId}`,
      {
        headers: {
          Authorization: `Basic ${process.env.REACT_APP_API_KEY}`,
          "Content-type": "application/json",
          Accept: "application/json",
        },
        crossdomain: true,
        withCredentials: false,
      }
    );
    return data;
  };

  const {
    data: tour,
    isLoading,
    isError,
  } = useQuery(
    ["tour"],
    async () => {
      return await getTourExperience(routerQuery.get("tour"));
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      onError: () => {
        toast({
          title: `An error has occurred please close the page`,
          position: `top`,
          status: `error`,
          isClosable: false,
          duration: 3000,
        });
      },
      enabled: !!routerQuery.get("tour"),
    }
  );

  const [selectedImage, setSelectedImage] = useState(null);

  const handleImageClick = (image) => {
    document
      .querySelectorAll(".mindar-ui-overlay")
      .forEach((el) => el.remove());
    setSelectedImage(image);
  };

  const ar_experiences = tour?.cmb2?.tours_ar_experience?.ar_experiences;

  const toast = useToast();
  useEffect(() => {
    setScreenHeight(window.innerHeight);
    setScreenWidth(window.innerWidth);
    if (!Boolean(routerQuery.get("tour"))) {
      toast({
        title: `Unable to load tour data.`,
        position: `top`,
        status: `error`,
        isClosable: false,
        duration: 5000,
      });
    }
  }, []);

  if (isLoading || isError) {
    return <FullPageSpinner />;
  }

  return (
    <Box height={`${screenHeight}px`}>
      {selectedImage === null && (
        <VStack mx={2} my={3} p={2} spacing={4} alignItems={"center"}>
          <Box>
            <Text
              fontSize={"14px"}
              fontWeight={600}
              color={"gray.400"}
              textAlign={"center"}
            >
              {ar_experiences?.length > 0
                ? `Select the image you would like to view in augmented reality.`
                : `No images are available for augmented reality viewing at the moment.`}
            </Text>
          </Box>
          <SimpleGrid
            maxH={`${screenHeight - 30}px`}
            overflowY={"scroll"}
            className="scrollable control-buttons"
            columns={2}
            w={"full"}
          >
            {(ar_experiences || [])?.map((ar_experience, index) => {
              return (
                <Box
                  key={index}
                  onClick={() => {
                    index !== selectedImage && handleImageClick(index);
                  }}
                  cursor={"pointer"}
                  p={1}
                  // bg={index === selectedImage ? "gray.400" : "transparent"}
                  flexShrink="0"
                  borderRadius={"7px"}
                  // height={"auto"}
                >
                  <ChakraImage
                    src={ar_experience?.tour_ar_photo}
                    alt="Ar experience"
                    border={"2px solid white"}
                    borderRadius={"6px"}
                  />
                </Box>
              );
            })}
          </SimpleGrid>
        </VStack>
      )}
      {(ar_experiences || [])?.map((ar, index) => {
        return (
          <>
            {index === selectedImage && (
              <Box className="container">
                <Button
                  className="control-buttons"
                  onClick={() => handleImageClick(null)}
                  m={2}
                  leftIcon={<FiChevronLeft fontSize={"18px"} />}
                >
                  Back
                </Button>
                <MindARViewer
                  mindFile={ar_experiences[selectedImage]?.ar_mind_file}
                  videoLink={ar_experiences[selectedImage]?.tour_ar_video}
                  pictureLink={ar_experiences[selectedImage]?.tour_ar_photo}
                />
              </Box>
            )}
          </>
        );
      })}
    </Box>
  );
}

export default App;
