import React, { useEffect, useRef, useState } from "react";
import { MindARThree } from "mind-ar/dist/mindar-image-three.prod.js";
import * as THREE from "three";
import { useToast, Box } from "@chakra-ui/react";

export default function MindARThreeViewer({
  mindFile,
  videoLink,
  pictureLink,
}) {
  const containerRef = useRef(null);
  const getMeta = (url) => {
    const img = new Image();
    img.src = url;

    return img.height / img.width;
  };

  const [targetFound, setTargetFound] = useState(false);
  const [windowHeight, setWindowHeight] = useState(0);

  const toast = useToast();
  useEffect(() => {
    setWindowHeight(window.innerHeight);
    const mindarThree = new MindARThree({
      container: containerRef.current,
      imageTargetSrc: mindFile,
      filterMinCF: 0.0001,
      filterBeta: 0.001,
      // warmupTolerance: 1,
    });
    const { renderer, scene, camera } = mindarThree;
    const anchor = mindarThree.addAnchor(0);
    const video = document.createElement("video");
    video.src = videoLink;
    video.autoplay = false;
    video.loop = true;
    video.setAttribute("crossorigin", "anonymous");
    video.setAttribute("playsinline", "");

    const videoTexture = new THREE.VideoTexture(video);
    videoTexture.minFilter = THREE.LinearFilter;
    videoTexture.magFilter = THREE.LinearFilter;
    videoTexture.encoding = THREE.sRGBEncoding;
    // videoTexture.format = 1030;

    const aspectRatio = getMeta(pictureLink);
    const geometry = new THREE.PlaneGeometry(1, aspectRatio);
    const material = new THREE.MeshBasicMaterial({
      map: videoTexture,
    });
    const plane = new THREE.Mesh(geometry, material);
    plane.position.set(0, 0, 0.1);
    anchor.group.add(plane);

    mindarThree.start();
    renderer.outputColorSpace = THREE.SRGBColorSpace;
    renderer.gammaFactor = 2.2;
    renderer.gammaOutput = true;
    renderer.setAnimationLoop(() => {
      renderer.render(scene, camera);
    });

    anchor.onTargetFound = () => {
      setTargetFound(true);
      video && video.play();
    };
    anchor.onTargetLost = () => {
      setTargetFound(false);
      video && video.pause();
    };

    return () => {
      video && video.pause();
      setTargetFound(true);
      video.currentTime = 0;
      renderer.setAnimationLoop(null);
      mindarThree.stop();
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (!targetFound) {
        toast({
          position: "top",
          render: () => (
            <Box
              rounded={"lg"}
              mt={12}
              p={2}
              bg="rgba(255, 255, 255, 0.4)"
              textAlign={"center"}
            >
              Check your selection and try again.
            </Box>
          ),
        });
      }
    }, 10000);
    return () => clearTimeout(timer);
  }, [targetFound]);

  return (
    <div
      style={{
        width: "100%",
        height: windowHeight === 0 ? `100vh` : `${windowHeight}px`,
      }}
      ref={containerRef}
    ></div>
  );
}
